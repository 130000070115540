import React, { Component } from 'react';
import { connect } from 'react-refetch';
import Constants from './Constants.js';
import _ from 'lodash';
import Site from './components/Site.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

const getUser = function() {
  const user = localStorage.getItem('user/1i9');
  return user ? JSON.parse(user) : {};
};

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      is_public: false,
      has_login: true,
      loading: true,
      dimmed: false,
      data: {},
      geo: {},
    }
    this.log_transaction = this.log_transaction.bind(this);
    this.clearUser = this.clearUser.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.isDimmed = this.isDimmed.bind(this);
  }

  isLoggedIn(){
    return this.state.user.user !== undefined;
  }

  clearUser() {
    this.log_transaction('logged out');
    this.props.catalogLazyListFetch();
    this.setState({user: {}});
    localStorage.removeItem('user/1i9');
    if(this.props.signinRequestReset)
      this.props.signinRequestReset();
  }

  componentDidMount(){
    this.props.catalogLazyListFetch();
    this.setState({selectedCatalog: Constants.CATALOG_ID});
    this.setState({user: getUser()});
    this.setState({startTime: (new Date()).getTime()});
  }

  componentDidUpdate(prevProps){
    if(_.get(prevProps, 'catalogListFetch.refreshing') && !_.get(this.props, 'catalogListFetch.refreshing')){
      if(this.props.catalogListFetch.rejected){
        this.setState({fatalError: 'Something went wrong or you have a bad connection. If the problem persists, please contact your Workrails Administrator.'});
        return;
      }
      const catalogOptions = [];
      this.props.catalogListFetch.value.data.forEach((catalog) => {
        if(this.isLoggedIn() || catalog.is_public){
          catalogOptions.push( { value: catalog.id, label: catalog.name, public: catalog.is_public });
        }
      });
      this.setState({catalogOptions});
      let opt = Constants.IS_DEV ? null : catalogOptions.find((opt) => opt.value === localStorage.getItem('savedCatalog'));
      if(!opt){
        opt = catalogOptions.find((opt) => opt.value === Constants.CATALOG_ID);
        if(!opt){
          opt = catalogOptions[0];
        }
      }
      this.handleCatalogChange(opt);
    }
    if(_.get(prevProps, 'catalogInfoFetch.refreshing') && !_.get(this.props, 'catalogInfoFetch.refreshing')){
      document.title=this.props.catalogInfoFetch.value.data.name;
      if(this.props.catalogInfoFetch.value.data.is_public || this.isLoggedIn()){
        this.props.catalogLazyFetch(this.props.catalogInfoFetch.value.data.id);
      }else{
        this.setState({loading: false});
      }
      // this.setState({is_public: this.props.catalogInfoFetch.value.data.is_public});
    }
    if(_.get(prevProps, 'catalogFetch.refreshing') && !_.get(this.props, 'catalogFetch.refreshing')){
      const data = _.get(this.props, 'catalogFetch.value.data') || {};
      console.log(this.props.catalogFetch);
      this.setState({loading: false});
      document.title=data.name || '';
      this.log_transaction('viewed', 'page', 'home');
    }
    if(_.get(prevProps, 'signInFetch.refreshing') && !_.get(this.props, 'signInFetch.refreshing')){
      if(_.get(this.props, 'signInFetch.rejected') === false){
        this.props.catalogLazyListFetch();
        this.setState({loading:true});
        const user = _.get(this.props,'signInFetch.value.data');
        this.setState({user: user}, this.log_transaction.bind(this,'logged in'));
        localStorage.setItem('user/1i9', JSON.stringify(user));
      }
    }
  }

  getUser(){
    return getUser();
  }

  isLoading(loading){
    if(loading !== undefined){
      this.setState({loading: loading});
      return;
    }
    return loading;
  }

  isDimmed(dimmed) {
    if (typeof dimmed === 'undefined') {
      return this.state.dimmed;
    }
    this.setState({dimmed});
  }

  renderDimmed() {
    if(!this.state.dimmed)
      return null;
    return (
        <div className="dimmed"></div>
    );
  }

  log_transaction(action, type, id, hname){
    if(!this.props.catalogInfoFetch){
      return;
    }
    const transaction = {};
    transaction.parent_type = 'catalog';
    transaction.parent_id = Constants.CATALOG_ID;
    if(!hname){
      hname = id;
    }
    if(this.isLoggedIn()){
      transaction.description = "User " + this.state.user.user.email + " " + action + " " + (type ? type + " '" + hname + "'" : '');
    }else{
      transaction.description = "A guest " + action + " " + (type ? type + " '" + hname + "'" : '');
    }
    transaction.request_data = {};
    transaction.company_id = this.props.catalogInfoFetch.value.data.company.id;
    transaction.request_data.user = {};
    transaction.request_data.object = {};
    transaction.request_data.user.id = this.state.user.user && this.state.user.user.id;
    transaction.request_data.user.email = this.state.user.user && this.state.user.user.email;
    if(hname){
      transaction.request_data.object.name = hname;
    }
    transaction.request_data.object.type = type;
    transaction.request_data.object.verb = action;
    transaction.request_data.object.id= id;
    this.props.lazyLogTransaction(transaction);
  }

  handleCatalogChange = (selectedOption) => {
    this.setState({ selectedCatalog: selectedOption });
    if(selectedOption){
      this.setState({loading: true});
      this.props.catalogLazyInfoFetch(selectedOption.value);
      localStorage.setItem('savedCatalog', selectedOption.value);
    }else{
      this.setState({loading: false});
    }
  }

  render() {
    if(this.state.fatalError){
      return <p>{this.state.fatalError}</p>
    }
    if(this.state.loading){
      return <FontAwesomeIcon icon="spinner" size="3x" spin className="loading" />;
    }
    return (
      <div className="App">
        <Site clearUser={this.clearUser} catalogOptions={this.state.catalogOptions} handleCatalogChange={this.handleCatalogChange} selectedCatalog={this.state.selectedCatalog} user={this.state.user} sendPasswordReset={this.props.lazySendPasswordReset} passwordResetFetch={this.props.passwordResetFetch} is_public={this.state.is_public} has_login={this.state.has_login} productRowsFetch={this.props.productRowsFetch} data={_.get(this.props, 'catalogFetch.value.data') ? _.get(this.props, 'catalogFetch.value.data') : _.get(this.props, 'catalogInfoFetch.value.data')} productRowsLazyFetch={this.props.productRowsLazyFetch} log_transaction={this.log_transaction} lazySignIn={this.props.lazySignIn} signInFetch={this.props.signInFetch} isLoggedIn={this.isLoggedIn} isDimmed={this.isDimmed}/>
        {this.renderDimmed()}
      </div>
    );
  }
}

export default connect(props => {
  return {
    catalogLazyListFetch: () => ({
      catalogListFetch: {
        method: 'GET',
        force: true,
        refreshing: true,
        url: Constants.API_BASE + '/companies/' + Constants.COMPANY_ID + '/cataloginfos',
        headers: {
          'cache-control': 'no-cache',
          'Content-Type': 'application/json',
          'token': Constants.AUTH,
        }
      },
    }),
    catalogLazyFetch: (catalogId) => ({
      catalogFetch: {
        url: Constants.API_BASE + "/catalogs/" + catalogId,
        method: 'GET',
        force: true,
        refreshing: true,
        headers: {
          'cache-control': 'no-cache',
          'Content-Type': 'application/json',
          'token': Constants.AUTH,
        }
      }
    }),
    catalogLazyInfoFetch: (catalogId) => ({
      catalogInfoFetch: {
        url: Constants.API_BASE + "/catalogs/" + catalogId + '/info',
        method: 'GET',
        force: true,
        refreshing: true,
        headers: {
          'cache-control': 'no-cache',
          'Content-Type': 'application/json',
          'token': Constants.AUTH,
        }
      }
    }),
    productRowsLazyFetch: (productId) => ({
      productRowsFetch: {
        url: `https://app.workrails.com/api/v1/product-rows/product-id/${productId}`,
          headers: {
            'Authorization':'ccc10ad7-b5aa-4c1d-8cbb-b4c3737efc8d',
          },
          force: true
      }
    }),
    lazyLogTransaction: transaction => ({
      logTransactionFetch: {
        url:  'https://app.workrails.com/api/v1/transactions/create',
        method: 'POST',
        headers: {
          'token':"88c56158-1a6d-4e90-8834-d5c22aafb3f9",
          'Content-Type': 'application/json',
        },
        body: `${JSON.stringify({data: transaction})}`,
      }
    }),
    lazySignIn: (payload) => ({
      signInFetch: {
        url: "https://api.workrails.com/beta/users/auth",
        method: 'POST',
        force: true,
        refreshing: true,
        headers: {
          'Content-Type': 'application/json',
        },
        body: `${JSON.stringify(payload)}`,
      },
      signinRequestReset: () => ({
        signinRequest: {
          value: null
        }
      })
    }),
    lazySendPasswordReset: (payload) => ({
      passwordResetFetch: {
        url: "https://app.workrails.com/api/v1/emails/send/password-reset",
        method: 'POST',
        force: true,
        refreshing: true,
        headers: {
          'Content-Type': 'application/json',
        },
        body: `${JSON.stringify(payload)}`,
      }
    }),
  }})(App);
