import React, { Component} from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import { Button, Navbar, NavbarBrand, Row, Container, Modal } from 'reactstrap';
import classNames from 'classnames';
import _ from 'lodash';
import Page from './Page.js';
import Login from './Login.js';
import Select from 'react-select';

class Site extends Component {
  constructor(props){
    super(props);

    this.state = {
      detailItem: null,
      activeTab: 0,
      accountDropdownOpen: false,
      loginWindowOpen: false,
      loginError: null,
      username: '',
      password: '',
    }
    this.toggleAccountDropdown = this.toggleAccountDropdown.bind(this);
    this.onForgotPWSubmit =this.onForgotPWSubmit.bind(this);
    this.toggleLoginModal = this.toggleLoginModal.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.setPageDetail = this.setPageDetail.bind(this);
    this.formManager = this.formManager.bind(this);
    this.onLoginSubmit = this.onLoginSubmit.bind(this);
  }

  componentDidUpdate(prevProps){
    if(_.get(prevProps, 'signInFetch.pending') && !_.get(this.props, 'signInFetch.pending')){
      this.setState({loginError: _.get(this.props, 'signInFetch.reason.cause.errorMessage')});
    }
    if(_.get(prevProps, 'passwordResetFetch.refreshing') && !_.get(this.props, 'passwordResetFetch.refreshing')){
      if(this.props.passwordResetFetch.rejected){
        this.setState({loginError: this.props.passwordResetFetch.reason.cause.error});
      }else{
        this.setState({loginSuccess: 'Password Reset Email Sent'});
      }
    }

  }

  toggleAccountDropdown(){
    this.setState({accountDropdownOpen: !this.state.accountDropdownOpen});
  }

  toggleLoginModal(){
    this.setState({loginWindowOpen: !this.state.loginWindowOpen});
  }

  setActiveTab(tab){
    if(this.state.activeTab !== tab){
      this.setState({
        activeTab: tab
      });
    }
  }

  setPageDetail(item){
    if(item && this.props.data){
      this.props.log_transaction('clicked', 'item', item.id, item.name);
    }
    this.setState({detailItem: item});
  }

  getPages(){
    //const data = this.props.data;
   // const activeTab = this.state.activeTab;
   // const setActiveTab = this.setActiveTab;
    if(!this.props.selectedCatalog){
      return null;
    }
    return(
      <Container className="children" fluid={true}>
        <Page user={this.props.user} isLoggedIn={this.props.isLoggedIn} isDimmed={this.props.isDimmed} productRowsFetch={this.props.productRowsFetch} productRowsLazyFetch={this.props.productRowsLazyFetch} log_transaction={this.props.log_transaction} data={this.props.data} detailItem={this.state.detailItem} setPageDetail={this.setPageDetail} />
      </Container>
    );
    /*switch(data.contents.display_as){
      case "tabs":
        return(
          <div className="body">
            <div className="tabs">
              <Nav tabs>
                {data.contents.pages.map((data,index) => {
                  return <NavItem key={index}><NavLink className={classNames({ active: activeTab === index})} onClick={() => {setActiveTab(index);}}>{data.title}</NavLink></NavItem>
                })}
              </Nav>
            </div>
            <TabContent className="pages" activeTab={this.state.activeTab}>
              {data.contents.pages.map((data, index) => {
                return <TabPane tabId={index} key={data.id} className={data.type + "-panel"} ><Page isLoggedIn={this.props.isLoggedIn} productRowsFetch={this.props.productRowsFetch} productRowsLazyFetch={this.props.productRowsLazyFetch} log_transaction={this.props.log_transaction} data={data} detailItem={this.state.detailItem} setPageDetail={this.setPageDetail} /></TabPane>
              })}
            </TabContent>
          </div>
        );
      case "sections":
        return(
          <div className="pages">
            {data.contents.pages.map((data, index) => {
              return <div key={data.id} index={index} className="page"><Page data={data} detailItem={this.state.detailItem} setPageDetail={this.setPageDetail} /></div>
            })}
          </div>
        );
      case "cards":
        return(
          <div className="pages">
            {data.contents.pages.map((data, index) => {
              return <div key={data.id} data={data} index={index} />
            })}
          </div>
        );
      case "accordian":
        break;
      default:
        return(
          <Container className="children" fluid={true}>
            {data.contents.pages.map((data, index) =>{
              return <Page isLoggedIn={this.props.isLoggedIn} productRowsFetch={this.props.productRowsFetch} productRowsLazyFetch={this.props.productRowsLazyFetch} log_transaction={this.props.log_transaction} key={data.id} data={data} index={index} detailItem={this.state.detailItem} setPageDetail={this.setPageDetail} />
            })}
          </Container>
        );
    }*/
  }

  formManager(e){
    this.setState({[e.target.name]:e.target.value});
  }

  onLoginSubmit(){
    if(this.state.username === ''){
      this.setState({loginError: "Email Required"});
      return;
    }else if(this.state.password === ''){
      this.setState({loginError: "Password Required"});
      return;
    }

    const { username: email } = this.state
    const ctxDomain = '@ptc.com'
    const valDomain = (email || '').replace(/.+@/, '@')
    const isValidDomain = [ctxDomain, '@workrails.com', '@xogito.com'].indexOf(valDomain) > -1
    const isValidEmail = [
      'wr.test.default+prodpartner@gmail.com',
      'wr.test.default+demopartner@gmail.com'
    ].indexOf(email) > -1
    if (!isValidDomain && !isValidEmail) {
      this.setState({ loginError: "You need a company email to login" });
      return;
    }

    this.props.lazySignIn({ email: this.state.username, password: this.state.password});
  }

  onForgotPWSubmit(){
    if(this.state.email.indexOf('@') >= this.state.email.indexOf('.') && this.state.email.indexOf('.') !== -1){
      this.setState({loginError: "Please enter a valid email address"});
      return;
    }
    this.setState({loginError: null});
    this.props.sendPasswordReset({ data: { email: this.state.email } });
  }

  getLoginButton(){
    if(!this.props.is_public && !this.props.isLoggedIn()){
      return null;
    }
    return this.props.isLoggedIn() ? <Button className='account-button' onClick={this.props.clearUser}>Logout</Button> : <Button className='account-button' onClick={this.toggleLoginModal}>Login</Button>;
  }

  render(){
    return(
        <BrowserRouter>
          <div>
            <Route
                exact
                path="*"
                render={() => {
                  return (
                      <div className={classNames("site", { list: !this.state.detailItem}, { detail: this.state.detailItem})}>
                        <Modal isOpen={this.state.loginWindowOpen} toggle={this.toggleLoginModal}>
                          <Login loginError={this.state.loginError} onForgotPWSubmit={this.onForgotPWSubmit} loginSuccess={this.state.loginSuccess} formManager={this.formManager} onLoginSubmit={this.onLoginSubmit}/>
                        </Modal>
                        <Navbar className="navbar-expand" color="light">
                          <Container>
                            <Row>
                              {this.props.data && <NavbarBrand href="/"><img src={this.props.data.company.image} className="logo" alt={this.props.data.company.title} /></NavbarBrand>}
                            </Row>
                          </Container>
                          {this.props.has_login &&
                          <div className="loginBox">
                            {this.props.catalogOptions.length > 1 &&
                            <Select className="select" value={this.props.selectedCatalog} onChange={(option) => this.props.handleCatalogChange(option)} options={this.props.catalogOptions} />}
                            {this.getLoginButton()}
                          </div>}
                        </Navbar>
                        {(!this.props.is_public && this.props.has_login && !this.props.isLoggedIn()) ?
                            <Row className="body private">
                              <div className="login col-lg-4 col-md-6 col-sm-8 mx-auto">
                                <Login loginError={this.state.loginError} loginSuccess={this.state.loginSuccess} formManager={this.formManager} onForgotPWSubmit={this.onForgotPWSubmit} onLoginSubmit={this.onLoginSubmit}/>
                              </div>
                            </Row>
                            : this.getPages()}
                        <footer>
                          <div>
                            <div>
                              <div className="poweredBy">Powered by <a href="https://www.workrails.com" target="_blank" rel="noopener noreferrer">WorkRails</a></div>
                            </div>
                          </div>
                        </footer>
                      </div>
                  );
                }}
            />
          </div>
        </BrowserRouter>
    );
  }
}

export default Site;
