import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { InputGroup, Input, Button } from 'reactstrap';

const overlayStyle = {
  position: 'absolute',
  zIndex: 2,
  backgroundColor: '#0e1213cc',
  width: '100%',
  height: '600px',
  left: '-25%',
  top: '-450px',
  transform: 'rotate(-24deg)'
}

const Overlay = () => ReactDOM.createPortal(<div style={overlayStyle} />, document.querySelector('body'))

class Login extends Component {
  constructor(props){
    super(props);

    this.state = {
      forgotPW: false,
    }

    this.forgotPassword = this.forgotPassword.bind(this);
    this.backToMain = this.backToMain.bind(this);
  }

  forgotPassword(){
    this.setState({forgotPW: true});
  }

  backToMain(){
    this.setState({forgotPW: false});
  }

  render(){
    if(this.state.forgotPW){
      return(
        <div className="login">
          <Overlay />
          <Button className="back" onClick={this.backToMain}>Back</Button>
          Forgot Password
          <Input type="text" onChange={this.props.formManager} name="email" placeholder="email" />
          <Button onClick={this.props.onForgotPWSubmit}>Send Reset Email</Button>
          {this.props.loginError && <p className="alert-danger">{this.props.loginError}</p>}
          {this.props.loginSuccess && <p className="alert-success">{this.props.loginSuccess}</p>}
        </div>
      );
    }
    return(
      <div className="login">
        <Overlay />
        <InputGroup>
          <Input type="text" onChange={this.props.formManager} name="username" placeholder="email" />
        </InputGroup>
        <InputGroup>
          <Input type="password" onChange={this.props.formManager} name="password" placeholder="password" />
        </InputGroup>
        <Button onClick={this.props.onLoginSubmit}>Login</Button>
        {this.props.loginError && <p className="alert-danger">{this.props.loginError}</p>}
        <Button className="forgot" onClick={this.forgotPassword}>Forgot Your Password?</Button>
      </div>
    );
  }
}

export default Login;
