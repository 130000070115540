import React, { Component } from 'react';
import { Link } from "react-router-dom";
import _ from 'lodash';
import { Button, Card, CardHeader, CardFooter, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap';
import classNames from 'classnames';


class Product extends Component {
  constructor(props){
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
  }


  setProductContainerDimensions() {
    setTimeout(() => {
      if(this.productContainer){
        this.productContainer.style.minHeight = `${this.props.className === 'product-featured' ? this.productContainer.offsetHeight : this.props.isGrid ? 210 : 150}px`;
        this.productContainer.style.margin = "15px 0px";
      }
    }, 0);
  }

  resize = () => this.clearProductContainerDimensions()
  clearProductContainerDimensions() {
    if(this.productContainer) {
      this.productContainer.style.minWidth = '';
    }
    setTimeout(() => {
      this.setProductContainerDimensions();
    }, 0);
  }

  componentDidMount(){
    this.setProductContainerDimensions();
    window.addEventListener('resize', this.resize);
  }
  componentDidUpdate(prevProps){
    if(_.get(prevProps, 'isGrid') !== _.get(this.props, 'isGrid')){
      this.clearProductContainerDimensions();
    }
    else {
      this.setProductContainerDimensions();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }


  handleSelect(){
    this.props.setPageDetail(this.props.data);
  }
  render(){
    const item = this.props.data;
    let categoryClassName = this.props.categoryClassNameMapping.find(categoryClassName => item.categories.some(c => _.includes(c.name.toLowerCase(), categoryClassName.category)))
    if(categoryClassName)
      categoryClassName = categoryClassName.className
    if(!categoryClassName) {
      return null;
    }

    const ppt = this.props.renderProductPriceTime(item);
    const subtitle = ppt ? ppt : null;
    return(
      <div ref={node => this.productContainer = node} className={classNames('product-container', this.props.className, this.props.isGrid ? 'col-lg-4' : 'col-lg-12')}>
        <div className={classNames(categoryClassName)}>
          <Link to={`/item/${item.id}`} onClick={this.handleSelect}>
            <Card className={classNames(categoryClassName)}>
              <CardHeader>
                <CardTitle>{item.name}</CardTitle>
                {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
              </CardHeader>
              <CardBody>
                <CardText>{this.props.isGrid ? _.truncate(item.description, {length: 300}) : item.description}</CardText>
              </CardBody>
              <CardFooter>
                <Button>Learn More</Button>
              </CardFooter>
            </Card>
          </Link>
          <div className={`sowBuilder ${this.props.isSowBuilderAdded(item) ? 'active' : ''}`}>
            <button className="added">Added to Cart</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Product;
