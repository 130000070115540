import React, { Component } from "react";
import WorkrailsInterpreter from '@workrails/workrails-interpreter';
//import WorkrailsInterpreter from './WorkrailsInterpreter/index';
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ProductDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "main",
      tabs: [],
      loading: false,
      productRows: "",
      options: [],
      selectedProductRowOptions: [],
      selectedProductOptions: []
    };

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    if(!this.props.data){
      this.props.backToPage();
      this.props.history.push('/');
      return;
    }
    const item = this.props.data;
    this.props.setPageDetail(item);

    const tabs = [
      {
        title:
          item.main_tab_label && item.main_tab_label.trim().length > 0
            ? item.main_tab_label
            : "Main",
        content: []
      }
    ];
    this.setState({ activeTab: tabs[0].title });
    let currentTab = 0;
    item.rows.forEach(row => {
      if (row.options.addToCatalogTab === true) {
        tabs.push({
          title: row.label,
          content: [row]
        });
        currentTab = tabs.length - 1;
      } else if (
        row.row_type === "style:header" ||
        row.options.hasOwnHeader === true
      ) {
        currentTab = 0;
        tabs[0].content.push(row);
      } else {
        tabs[currentTab].content.push(row);
      }
    });
    this.setState({ tabs });
    window.scrollTo(0, 0);


    if(item.name.includes('Customer Maturity')) {
      setTimeout(function () {
        const headerElem = document.querySelector(".detailCardContainer .card .card-header");
        const titleElem = document.querySelector(".detailCardContainer .card .card-header .card-title");
        const descElem = document.querySelector(".detailCardContainer .card .card-body > div .description");
        if (descElem) {
          titleElem.appendChild(descElem);
        }

        const imgElem = document.querySelector(".detailCardContainer .card .card-body .tabContent > .section:first-child img");
        if (imgElem) {
          headerElem.insertBefore(imgElem, titleElem);
        }
      }, 0)
    }



    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    if (!this.props.isSowBuilderPreviewShowing()) {
      this.props.toggleSowBuilderPreview();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      _.get(prevProps, "productRowsFetch.pending") &&
      !_.get(this.props, "productRowsFetch.pending")
    ) {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);

    if (this.props.isSowBuilderPreviewShowing()) {
      this.props.toggleSowBuilderPreview();
    }
  }

  handleResize(e) {
    this.props.setSowBuilderPreviewHeight(e);
  }

  handleTabClick(e) {
    this.setState({ activeTab: e.target.value });
  }

  render() {
    if(!this.props.data){
      return null;
    }
    const subtitle = this.props.renderProductPriceTime(this.props.data);
    return (
      <Container className="detail">
        <Button
          onClick={this.props.backToPage}
          color="link"
          className="backButton"
        >
          <Link to="/">
            <FontAwesomeIcon icon="long-arrow-alt-left" />
            Back
          </Link>
        </Button>
        <div className='detailCardContainer'>
          {this.props.data && this.props.data.description ? (
            <Card onClick={this.handleSelect}>
              <CardHeader>
                <CardTitle>{this.props.data.name}</CardTitle>
                {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
                <div className="cta">
                  {this.props.isSowBuilderAdded(this.props.data) ? (
                    <button
                      className="added"
                      onClick={() =>
                        this.props.handleSowBuilderRemove(this.props.data)
                      }
                    >
                      Added to Cart
                    </button>
                  ) : (
                    <button
                      className="add"
                      onClick={() =>
                        this.props.handleSowBuilderAdd(this.props.data)
                      }
                    >
                      Add to Cart
                    </button>
                  )}
                </div>
              </CardHeader>
              <CardBody style={{ overflow: "auto" }}>
                <WorkrailsInterpreter selectedProductOptions={[]} isLoggedIn={this.props.isLoggedIn()} data={this.props.data}/>
              </CardBody>
            </Card>
          ) : (
            <h1>Something went wrong.</h1>
          )}
          {this.props.getSowBuilderSummary(this.props.data)}
        </div>
      </Container>
    );
  }
}

export default withRouter(ProductDetail);
